import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@common/components/icon';
import { FileViewer } from '@common/components/file-viewer';
import Spinner from '@common/components/spinner';
import { DocumentIcon } from '@modules/documents/components/document-icon';
import { Attachment, FileInputValue } from '@common/types/objects';
import { Document } from '@modules/documents/types';
import { FileInput } from '@common/components/form/inputs/file';
import type { ReceivedQuestionComponentFileUpload } from '../../../../../modules/learning/types/objects';
import BaseScreenComponent from './base-screen-component';
import { useChangeFileInput } from '../../inputs/file/hooks';

export type FileUploadScreenComponentValue = FileInputValue[];

type FileUploadScreenComponentOwnProps = {
  item: ReceivedQuestionComponentFileUpload;
  value?: FileUploadScreenComponentValue;
  onChange?: (value: FileUploadScreenComponentValue) => void;
  dropzoneCallToAction?: string;
};

const fileKey = (file: FileInputValue) => {
  return file instanceof File ? URL.createObjectURL(file) : file.id;
};

export const FileUploadScreenComponent = ({
  item,
  value,
  onChange,
  dropzoneCallToAction,
}: FileUploadScreenComponentOwnProps) => {
  const { t } = useTranslation();
  const readOnlyMode = !onChange;
  const multiple = item?.parameters?.allow_multiple_uploads;

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [fileInPreview, setFileInPreview] = useState<string | undefined>(undefined);
  const { files, setFiles, onChange: onChangeFileInput } = useChangeFileInput(multiple);

  const uploaded = useMemo(() => {
    return value?.filter((v) => !v.processing) as Attachment[] || [];
  }, [value]);

  useEffect(() => {
    if (!readOnlyMode) {
      onChange(files);
      setIsUploading(!!files.filter((f) => f instanceof File).length);
    }
  }, [files, readOnlyMode]);

  return (
    <BaseScreenComponent item={item} className="FileUploadScreenComponent">
      {
        fileInPreview && (
          <FileViewer
            isVisible
            content={uploaded.map((attachment) => ({ attachment }))}
            setIsVisible={(isVisible) => !isVisible && setFileInPreview(undefined)}
            initialPage={uploaded.findIndex(({ id }) => id === fileInPreview)}
          />
        )
      }
      {
        value?.map((file: FileInputValue) => {
          return (
            <div key={fileKey(file)} className="filePreview">
              {
                file.processing ?
                  <Spinner size="large" centered /> :
                  <>
                    <DocumentIcon item={{ attachment: file } as Document} />
                    <a onClick={() => setFileInPreview(file.id)}>
                      { file.file_name }
                    </a>
                  </>
              }
              {
                !readOnlyMode && (
                  <Icon
                    type="delete"
                    onClick={() => {
                      setFiles(value.filter((valueFile: FileInputValue) => {
                        return file !== valueFile;
                      }));
                    }}
                  />
                )
              }
            </div>
          );
        })
      }
      {
        (!readOnlyMode || value === undefined) && (
          <FileInput
            className="Screen__Component__FileUploadDropzone"
            value={value}
            multiple={multiple}
            disabled={readOnlyMode || isUploading}
            onChange={onChangeFileInput}
            placeholder={t(
              dropzoneCallToAction ||
              'survey:question_type_file_upload_preview_dropzone_label'
            )}
          />
        )
      }
    </BaseScreenComponent>
  );
};
